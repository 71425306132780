import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";
import $ from "jquery";

const KampanieGdnPage = ({ location: { pathname } }) => {
  if (typeof window !== `undefined`) {
    (function ($) {
      $.fn.shuffleLetters = function (prop) {
        var options = $.extend(
          {
            step: 1, // How many times should the letters be changed
            fps: 30, // Frames Per Second
            text: "", // Use this text instead of the contents
            callback: function () {}, // Run once the animation is complete
          },
          prop
        );

        return this.each(function () {
          var el = $(this),
            str = "";

          // Preventing parallel animations using a flag;
          if (el.data("animated")) {
            return true;
          }
          el.data("animated", true);

          if (options.text) {
            str = options.text.split("");
          } else {
            str = el.text().split("");
          }

          // The types array holds the type for each character;
          // Letters holds the positions of non-space characters;

          var types = [],
            letters = [];

          // Looping through all the chars of the string

          for (var i = 0; i < str.length; i++) {
            var ch = str[i];

            if (ch === " ") {
              types[i] = "space";
              continue;
            } else if (/[a-z]/.test(ch)) {
              types[i] = "lowerLetter";
            } else if (/[A-Z]/.test(ch)) {
              types[i] = "upperLetter";
            }

            letters.push(i);
          }

          el.html("");

          // Self executing named function expression:

          (function shuffle(start) {
            // This code is run options.fps times per second
            // and updates the contents of the page element

            var i,
              len = letters.length,
              strCopy = str.slice(0); // Fresh copy of the string

            if (start > len) {
              // The animation is complete. Updating the
              // flag and triggering the callback;

              el.data("animated", false);
              options.callback(el);
              return;
            }

            // All the work gets done here
            for (i = Math.max(start, 0); i < len; i++) {
              // The start argument and options.step limit
              // the characters we will be working on at once

              if (i < start + options.step) {
                // Generate a random character at thsi position
                strCopy[letters[i]] = randomChar(types[letters[i]]);
              } else {
                strCopy[letters[i]] = "";
              }
            }

            el.text(strCopy.join(""));

            setTimeout(function () {
              shuffle(start + 1);
            }, 2550 / options.fps);
          })(-options.step);
        });
      };

      function randomChar(type) {
        var pool = "";

        if (type === "lowerLetter") {
          pool = "abcdefghijklmnopqrstuvwxyz";
        } else if (type === "upperLetter") {
          pool = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        }

        var arr = pool.split("");
        return arr[Math.floor(Math.random() * arr.length)];
      }
    })($);

    $(function () {
      var container = $("#shuffle");

      function shuffle(text) {
        container.shuffleLetters({
          text: text,
        });
      }

      var arr = [
        "zainteresowaniach użytkowników",
        "danych demograficznych",
        "tematyce stron docelowych",
        "wybranych witrynach",
        "słowach kluczowych w witrynie",
      ];
      var i = 0;
      setInterval(function () {
        shuffle(arr[i++ % arr.length]);
      }, 2000);
    });
  }

  return (
    <Layout
      seo={{
        title: "Kampanie w sieci reklamowej Google Display Network GDN",
        description:
          "Kampanie Google Ads w sieci reklamowej Google Display Network (GDN) zapewniają dostęp do powierzchni reklamowej tysięcy serwisów. W naszej agencji specjalizujemy się w tworzeniu kampanii reklamowych w sieci reklamowej GDN, które pomogą Ci zwiększyć zasięg Twojej marki i przyciągnąć nowych klientów.",
        href: pathname,
        lang: "pl",
      }}
    >
      <span className="anchor" id="home"></span>
      <div className="subpage-header subpage-header--single-offer">
        <h1 className="subpage-header__title">
          Kampanie w sieci <br /> <span>reklamowej GDN</span>
        </h1>
      </div>

      <div className="single-offer-menu-container">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/uslugi/#ppc">
              <div className="back-wrapper">
                <span className="back-wrapper__arrow"></span>
                <p className="back-wrapper__title">Usługi</p>
              </div>
            </Link>
            <p className="nav-title">Specjalizacje:</p>
            <ul>
              <li>
                <Link to="/audyt-kampanii-google-facebook-ads/">
                  Audyt kampanii <br /> Google/Facebook Ads
                </Link>
              </li>
              <li>
                <Link to="/kampanie-googleads-search/">
                  Kampanie Google Ads search
                </Link>
              </li>
              <li>
                <Link to="/kampanie-wideo-na-youtube/">
                  Kampanie Youtube Ads
                </Link>
              </li>
              <li className="current">
                <a href="#home">Kampanie GDN</a>
              </li>
              <li>
                <Link to="/kampanie-produktowe-product-listing-ads/">
                  Kampanie PLA
                </Link>
              </li>
              <li>
                <Link to="/tiktok-ads/">Kampanie TikTok Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-facebook-ads/">Kampanie Facebook Ads</Link>
              </li>
              <li>
                <Link to="/kampanie-na-instagramie/">
                  Kampanie na Instagramie
                </Link>
              </li>
              <li>
                <Link to="/kampanie-linkedin-ads/">Kampanie LinkedIn</Link>
              </li>
              <li>
                <Link to="/banery-statyczne-i-dynamiczne/">
                  Banery statyczne i dynamiczne
                </Link>
              </li>
              <li>
                <Link to="/kampanie-zagraniczne/">
                  Kampanie PPC na rynki zagraniczne
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <section className="single-offer-section-text-left single-offer-section-text-left--cat-2 single-offer-section-text-left--gdn">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Google Display Network
                </h2>
                <p className="text-col__desc">
                  Sieć reklamowa Google to sieć ponad kilka milionów witryn
                  internetowych, która dociera do ponad 90% internautów na całym
                  świecie. Tak duży zbiór stron daje możliwość pojawienia się
                  reklamy graficznej dokładnie tam, gdzie w danym momencie
                  znajduje się użytkownik - zarówno na urządzeniach mobilnych i
                  komputerach. Sieć GDN obejmuje dodatkowo szeroką gamę
                  aplikacji i usługi należące do Google, takie jak YouTube czy
                  Gmail.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/gdn-campaign-image-1.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text-right">
        <div className="row">
          <div className="col-xl-9 offset-xl-3 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/gdn-campaign-image-2.jpg")}
                  alt=""
                />
              </div>
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  Jedna reklama, <br /> wiele możliwości
                </h2>
                <p className="text-col__desc">
                  W zależności od przestrzeni reklamowej, którą udostępnia dany
                  serwis internetowy, banery mogą pojawiać się w wielu
                  rozmiarach oraz formatach. Reklamodawcy mogą wykorzystać w
                  swoich kampaniach grafiki statyczne, animowane (w technologii
                  AMP HTML lub GIF) oraz zaciągnąć do banerów materiały video
                  bezpośrednio z konta youtube. Skutecznym rozwiązaniem są
                  również banery elastyczne, które tworzone są automatycznie na
                  podstawie kilku wybranych grafik czy zdjęć oraz tekstów
                  reklamowych. Takie rozwiązanie pozwala na samoistne
                  dopasowanie się wyświetlanego banery do udostępnionej
                  powierzchni reklamowej serwisu, co znacząco wpływa na ich
                  częstotliwość wyświetlania.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="single-offer-section-text-small single-offer-section-text-small--gdn">
        <div className="row">
          <div className="col-lg-6 offset-lg-4 text-col">
            <h2 className="text-col__title">
              Sprawdź nasze <br />
              <span>portfolio banerów</span>
            </h2>
            <div className="text-wrapper--inner-action">
              <Link to="/" className="btn btn-line">
                pobierz <strong>portfolio</strong>
              </Link>
            </div>
          </div>
        </div>
      </section> */}
      <section className="single-offer-section-text-left single-offer-section-text-left--gdn-second">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3">
            <div className="row align-items-center">
              <div className="col-lg-6 text-col">
                <h2 className="text-col__title mb-40">
                  W odpowiednim <br /> miejscu do właściwego <br /> użytkownika
                </h2>
                <p className="text-col__desc">
                  Kampanie w sieci reklamowej pomagają w osiągnięciu różnych
                  celów - budowy świadomości marki, generowania sprzedaży,
                  pozyskania leadów. Wszystko to dzięki kierowaniu, które
                  pozwala wyświetlić reklamę odpowiednim użytkownikom w
                  odpowiednim miejscu. Reklamy graficzne możemy kierować na dwa
                  sposoby: skupiając się na odbiorcach, ich zainteresowaniach,
                  demografii i zamiarach lub kontekstowo - na strony o danej
                  treści lub konkretne witryny czy aplikacje. Możemy także
                  łączyć te dwie metody, żeby dotrzeć na przykład do osoby
                  zainteresowanej inwestycjami w nieruchomości, na stronie
                  internetowej która dotyczy inwestowania.
                </p>
              </div>
              <div className="col-lg-6 img-col">
                <img
                  className="img-fluid img-col__image"
                  src={require("../assets/images/gdn-campaign-image-3.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-text single-offer-section-text--gdn">
        <div className="row">
          <div className="col-lg-8 offset-lg-3 text-col">
            <h2 className="text-col__title">
              <span>
                Targetuj reklamę <br /> banerową po:
              </span>{" "}
              <br />
              <span id="shuffle">zainteresowaniach użytkowników</span>
            </h2>
          </div>
        </div>
      </section>
      <section className="single-offer-section-images">
        <div className="row">
          <div className="col-xl-8 offset-xl-4 col-lg-9 offset-lg-3 img-col">
            <div className="row">
              <div className="col-md-6">
                <div className="inner">
                  <p className="inner__title">Reklama statyczna</p>
                  <img
                    src={require("../assets/images/gdn-example-1.png")}
                    alt=""
                    className=" inner__image"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inner">
                  <p className="inner__title">Reklama elastyczna</p>
                  <img
                    src={require("../assets/images/gdn-example-2.png")}
                    alt=""
                    className=" inner__image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="single-offer-section-contact">
        <div className="form-wrapper">
          <h2 className="single-offer-section-contact__title mb-40">
            Skontaktuj się z nami! <br />
            Wypełnij <span>formularz</span>
          </h2>
          <ContactFormDark locales={locales["pl"]} messageRequired={false} />
        </div>
        <img
          className="img-fluid single-offer-section-contact__hand"
          src={require("../assets/images/home-contact-hand-right.png")}
          alt=""
        />
      </section>
    </Layout>
  );
};
export default KampanieGdnPage;
